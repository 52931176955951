import React, { useState, useEffect, useRef } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPlus,
  faCircle,
  faCheck,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import "swiper/css"
import "swiper/css/navigation"

export const PriceSwiper = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const swiperRef = useRef()
  const [isMobile, setIsMobile] = useState(
    typeof window !== "undefined" && window.innerWidth < 768
  )

  const onSlideChange = swiper => {
    setActiveIndex(swiper.activeIndex)
  }

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const recommendPlanIndex = 1

  // モバイルの場合のみクラスを切り替える
  const containerClass =
    isMobile && activeIndex === recommendPlanIndex ? "block mt-28" : "hidden"
  return (
    <>
      <div
        className={`relative w-full mx-auto md:mt-32 md:block ${containerClass}`}
      >
        <div className="recommend-badge absolute">
          <div className="square1 absolute rounded"></div>
          <div className="square2 absolute rounded"></div>
          <div className="recommend-badge__text absolute text-white text-center font-bold text-2xl">
            おすすめ
          </div>
        </div>
      </div>
      <div className="swiper-wrapper relative" style={{ zIndex: 10 }}>
        <Swiper
          modules={[Navigation]}
          onSlideChange={onSlideChange}
          onBeforeInit={swiper => {
            swiperRef.current = swiper
          }}
          slidesPerView={1}
          spaceBetween={20}
          breakpoints={{ 768: { slidesPerView: 3, noSwiping: true } }}
        >
          <SwiperSlide>
            <div className="rounded-lg bg-white border border-lightgrey px-5 py-8 h-full">
              <div className="text-center mb-5">
                <h3 className="text-2xl font-bold mb-2">開設支援プラン</h3>
                <p className="mb-5">
                  アカウント開設するサポートと、
                  <br />
                  自社で運用できる体制づくりがしたい
                </p>
                <p className="mb-2">
                  初期費用<span className="text-4xl font-bold">15</span>
                  <span className="font-bold text-xl">万円</span>
                </p>
                <span className="text-primary">
                  <FontAwesomeIcon icon={faPlus} size="2xl" />
                </span>
                <h4 className="mb-5">
                  <span className="text-2xl">月額</span>
                  <span className="text-5xl font-bold">20</span>
                  <span className="text-3xl font-bold">万円</span>
                </h4>
                <button className="bg-primary text-white font-bold py-2 w-full rounded">
                  資料ダウンロード
                </button>
              </div>
              <ul className="mb-4 flex flex-col gap-y-1">
                <li className="flex items-center gap-x-1">
                  <FontAwesomeIcon icon={faCircle} className="text-primary" />
                  <h5 className="font-bold">初期戦略設計</h5>
                </li>
                <li className="ml-5 flex items-center gap-x-1">
                  <FontAwesomeIcon icon={faCheck} className="text-primary" />
                  <p className="">アカウント開設支援</p>
                </li>
                <li className="ml-5 flex items-center gap-x-1">
                  <FontAwesomeIcon icon={faCheck} className="text-primary" />
                  <p className="">KPI設定</p>
                </li>
                <li className="ml-5 flex items-center gap-x-1">
                  <FontAwesomeIcon icon={faCheck} className="text-primary" />
                  <p className="">他社調査</p>
                </li>
                <li className="ml-5 text-sm">を含む、10項目すべて</li>
              </ul>
              <ul className="mb-3 flex flex-col gap-y-1">
                <li className="flex items-center gap-x-1">
                  <FontAwesomeIcon icon={faCircle} className="text-primary" />
                  <h5 className="font-bold">キャンペーン代行</h5>
                </li>
                <li className="ml-5 text-sm">なし</li>
              </ul>
              <ul className="flex flex-col gap-y-1">
                <li className="flex items-center gap-x-1">
                  <FontAwesomeIcon icon={faCircle} className="text-primary" />
                  <h5 className="font-bold">運用サポート</h5>
                </li>
                <li className="ml-5 flex items-center gap-x-1">
                  <FontAwesomeIcon icon={faCheck} className="text-primary" />
                  <p className="">キャンペーンコンサル（1〜2回）</p>
                </li>
                <li className="ml-5 flex items-center gap-x-1">
                  <FontAwesomeIcon icon={faCheck} className="text-primary" />
                  <p className="">MTG2週に1回で内容添削</p>
                </li>
              </ul>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="recommend-plan p-1 h-full">
              <div className="rounded-lg bg-white px-5 py-8 h-full">
                <div className="text-center mb-5">
                  <h3 className="text-2xl font-bold mb-2">
                    運用おまかせライト
                  </h3>
                  <p className="mb-5">
                    日々の投稿と、キャンペーンまわりの
                    <br />
                    運用も代行してほしい
                  </p>
                  <p className="mb-2">
                    初期費用<span className="text-4xl font-bold">15</span>
                    <span className="font-bold text-xl">万円</span>
                  </p>
                  <span className="text-primary">
                    <FontAwesomeIcon icon={faPlus} size="2xl" />
                  </span>
                  <h4 className="mb-5">
                    <span className="text-2xl">月額</span>
                    <span className="text-5xl font-bold">30</span>
                    <span className="text-3xl font-bold">万円</span>
                  </h4>
                  <button className="bg-primary text-white font-bold py-2 w-full rounded">
                    資料ダウンロード
                  </button>
                </div>
                <ul className="mb-4 flex flex-col gap-y-1">
                  <li className="flex items-center gap-x-1">
                    <FontAwesomeIcon icon={faCircle} className="text-primary" />
                    <h5 className="font-bold">初期戦略設計</h5>
                  </li>
                  <li className="ml-5 flex items-center gap-x-1">
                    <FontAwesomeIcon icon={faCheck} className="text-primary" />
                    <p className="">アカウント開設支援</p>
                  </li>
                  <li className="ml-5 flex items-center gap-x-1">
                    <FontAwesomeIcon icon={faCheck} className="text-primary" />
                    <p className="">KPI設定</p>
                  </li>
                  <li className="ml-5 flex items-center gap-x-1">
                    <FontAwesomeIcon icon={faCheck} className="text-primary" />
                    <p className="">他社調査</p>
                  </li>
                  <li className="ml-5 text-sm">を含む、10項目すべて</li>
                </ul>
                <ul className="mb-3 flex flex-col gap-y-1">
                  <li className="flex items-center gap-x-1">
                    <FontAwesomeIcon icon={faCircle} className="text-primary" />
                    <h5 className="font-bold">キャンペーン代行</h5>
                  </li>
                  <li className="ml-5 flex items-center gap-x-1">
                    <FontAwesomeIcon icon={faCheck} className="text-primary" />
                    <p className="">アカウント開設支援</p>
                  </li>
                  <li className="ml-5 flex items-center gap-x-1">
                    <FontAwesomeIcon icon={faCheck} className="text-primary" />
                    <p className="">KPI設定</p>
                  </li>
                  <li className="ml-5 flex items-center gap-x-1">
                    <FontAwesomeIcon icon={faCheck} className="text-primary" />
                    <p className="">他社調査</p>
                  </li>
                  <li className="ml-5 text-sm">を含む、9項目</li>
                </ul>
                <ul className="flex flex-col gap-y-1">
                  <li className="flex items-center gap-x-1">
                    <FontAwesomeIcon icon={faCircle} className="text-primary" />
                    <h5 className="font-bold">運用サポート</h5>
                  </li>
                  <li className="ml-5 flex items-center gap-x-1">
                    <FontAwesomeIcon icon={faCheck} className="text-primary" />
                    <p className="">投稿カレンダー作成</p>
                  </li>
                  <li className="ml-5 flex items-center gap-x-1">
                    <FontAwesomeIcon icon={faCheck} className="text-primary" />
                    <p className="">投稿コンテンツ企画（1投稿/週）</p>
                  </li>
                  <li className="ml-5 flex items-center gap-x-1">
                    <FontAwesomeIcon icon={faCheck} className="text-primary" />
                    <p className="">クリエイティブ制作（静止画）</p>
                  </li>
                  <li className="ml-5 text-sm">を含む、5項目</li>
                </ul>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="rounded-lg bg-white border border-lightgrey px-5 py-8 h-full">
              <div className="text-center mb-5">
                <h3 className="text-2xl font-bold mb-2">運用おまかせプロ</h3>
                <p className="mb-5">
                  アカウント開設から運用全般まで、
                  <br />
                  運用の全てをお任せしたい
                </p>
                <p className="mb-2">
                  初期費用<span className="text-4xl font-bold">15</span>
                  <span className="font-bold text-xl">万円</span>
                </p>
                <span className="text-primary">
                  <FontAwesomeIcon icon={faPlus} size="2xl" />
                </span>
                <h4 className="mb-5">
                  <span className="text-2xl">月額</span>
                  <span className="text-5xl font-bold">50</span>
                  <span className="text-3xl font-bold">万円</span>
                </h4>
                <button className="bg-primary text-white font-bold py-2 w-full rounded">
                  資料ダウンロード
                </button>
              </div>
              <ul className="mb-4 flex flex-col gap-y-1">
                <li className="flex items-center gap-x-1">
                  <FontAwesomeIcon icon={faCircle} className="text-primary" />
                  <h5 className="font-bold">初期戦略設計</h5>
                </li>
                <li className="ml-5 flex items-center gap-x-1">
                  <FontAwesomeIcon icon={faCheck} className="text-primary" />
                  <p className="">アカウント開設支援</p>
                </li>
                <li className="ml-5 flex items-center gap-x-1">
                  <FontAwesomeIcon icon={faCheck} className="text-primary" />
                  <p className="">KPI設定</p>
                </li>
                <li className="ml-5 flex items-center gap-x-1">
                  <FontAwesomeIcon icon={faCheck} className="text-primary" />
                  <p className="">他社調査</p>
                </li>
                <li className="ml-5 text-sm">を含む、10項目すべて</li>
              </ul>
              <ul className="mb-3 flex flex-col gap-y-1">
                <li className="flex items-center gap-x-1">
                  <FontAwesomeIcon icon={faCircle} className="text-primary" />
                  <h5 className="font-bold">キャンペーン代行</h5>
                </li>
                <li className="ml-5 flex items-center gap-x-1">
                  <FontAwesomeIcon icon={faCheck} className="text-primary" />
                  <p className="">アカウント開設支援</p>
                </li>
                <li className="ml-5 flex items-center gap-x-1">
                  <FontAwesomeIcon icon={faCheck} className="text-primary" />
                  <p className="">KPI設定</p>
                </li>
                <li className="ml-5 flex items-center gap-x-1">
                  <FontAwesomeIcon icon={faCheck} className="text-primary" />
                  <p className="">他社調査</p>
                </li>
                <li className="ml-5 text-sm">を含む、10項目すべて</li>
              </ul>
              <ul className="flex flex-col gap-y-1">
                <li className="flex items-center gap-x-1">
                  <FontAwesomeIcon icon={faCircle} className="text-primary" />
                  <h5 className="font-bold">運用サポート</h5>
                </li>
                <li className="ml-5 flex items-center gap-x-1">
                  <FontAwesomeIcon icon={faCheck} className="text-primary" />
                  <p className="">投稿カレンダー作成</p>
                </li>
                <li className="ml-5 flex items-center gap-x-1">
                  <FontAwesomeIcon icon={faCheck} className="text-primary" />
                  <p className="">投稿コンテンツ企画（1投稿/週）</p>
                </li>
                <li className="ml-5 flex items-center gap-x-1">
                  <FontAwesomeIcon icon={faCheck} className="text-primary" />
                  <p className="">クリエイティブ制作（静止画）</p>
                </li>
                <li className="ml-5 text-sm">を含む、7項目すべて</li>
              </ul>
            </div>
          </SwiperSlide>
        </Swiper>
        {isMobile && (
          <>
            <button
              onClick={() => swiperRef.current?.slidePrev()}
              disabled={activeIndex === 0}
              className="absolute z-10 prev-button text-white w-10 h-10 flex items-center justify-center disabled:hidden"
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <button
              onClick={() => swiperRef.current?.slideNext()}
              disabled={activeIndex === 2}
              className="absolute z-10 next-button text-white w-10 h-10 flex items-center justify-center disabled:hidden"
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </>
        )}
      </div>
    </>
  )
}
