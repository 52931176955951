import React, { useState } from "react"
import { Link } from "gatsby"
import { DefaultHeader } from "components/headers/default-header"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons"
import { OptionsSwiper } from "components/swiper/sns-swiper"
import { ContentsSwiper } from "components/swiper/sns-contents"
import { PriceSwiper } from "components/swiper/sns-price"

const questions = [
  {
    title: "契約はアカウント単位ですか？",
    answer: "はい、X/Twitterアカウントごとの契約になります。",
  },
  {
    title: "運用対象SNSは、X/Twitterだけですか？",
    answer:
      "運用代行の対象はX/Twitterアカウントのみですが、他のSNSとの総合的なカスタマージャーニー設計を行います。",
  },
  {
    title: "X/Twitter広告運用も依頼できますか？",
    answer: "はい、可能です。おまかせプロプランにご契約の上、ご相談ください。",
  },
  {
    title:
      "UGCのサイト表示、QRコードデジタル抽選会、診断コンテンツ企画は実施できますか？",
    answer: "はい、実施可能です。別途ご相談ください。",
  },
]
const inputs = [
  {
    label: "会社名・団体名",
    placeholder: "株式会社PARKLoT",
    type: "text",
    isTwoCol: false,
  },
  [
    {
      label: "姓",
      placeholder: "山田",
      type: "text",
      isTwoCol: true,
    },
    {
      label: "名",
      placeholder: "太郎",
      type: "text",
      isTwoCol: true,
    },
  ],
  {
    label: "メールアドレス",
    placeholder: "sample@park-lot.com",
    type: "email",
    isTwoCol: false,
  },
  {
    label: "電話番号",
    placeholder: "090-xxxx-xxxx",
    type: "tel",
    isTwoCol: false,
  },
]

const ActingSnsAccount = ({ location }) => {
  // よくある質問の開閉
  const [openQuestions, setOpenQuestions] = useState([])

  const handleIconClick = index => {
    if (openQuestions.includes(index)) {
      setOpenQuestions(openQuestions.filter(i => i !== index))
    } else {
      setOpenQuestions([...openQuestions, index])
    }
  }
  // フォーム入力値
  const [formValues, setFormValues] = useState({})
  const [secondFormValues, setSecondFormValues] = useState({})
  const handleChange = (e, type) => {
    const { name, value } = e.target
    if (type !== "fv") {
      setSecondFormValues({ ...secondFormValues, [name]: value })
      return
    } else {
      setFormValues({ ...formValues, [name]: value })
    }
  }

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="X運用の成果を最大化する | SNSアカウント運用"
          pagedesc="マンガとSNSキャンペーンの掛け合わせで効果倍増！"
          pagepath={location.pathname}
        />
        <main className="sns-account font-noto leading-relaxed">
          <section className="fv md:flex">
            <div className="md:flex-1 fv md:px-10 md:pt-0 px-5 pt-8">
              <div className="h-full md:mt-24">
                <h1 className="md:text-3xl text-xl font-bold leading-relaxed mb-5 md:mb-8">
                  <span className="px-3 py-1 rounded bg-accent text-white inline-block mr-2 mb-2">
                    売り上げに繋がる運用
                  </span>
                  も<br />
                  <span className="px-3 py-1 rounded bg-primary text-white inline-block mr-2 mb-3">
                    ファンが増えるキャンペーン
                  </span>
                  も<br />
                  <span className="flex items-baseline">
                    <StaticImage
                      src="../../images/brand/blue.png"
                      width={320}
                      alt="PARKLoT"
                    />
                    <span>に</span>
                  </span>
                  <span className="text-4xl inline-block mt-2">
                    おまかせください！
                  </span>
                </h1>
                <p className="white-shadow leading-loose md:mb-6">
                  PARKLoTのX運用代行サービスなら戦略的なアカウント設計から
                  <br />
                  日々の運用ツールを使用したキャンペーンの実施まで
                  <br />
                  X運用の成果を最大化する施策をワンストップでご提供します
                </p>
                <button className="border text-center bg-white border-primary rounded py-2 px-5 text-primary font-bold transition-all hover:text-white hover:bg-primary md:block hidden">
                  お役立ち資料ダウンロード
                </button>
              </div>
            </div>
            <div className="yellow-stripe md:flex md:flex-col md:justify-center md:w-96 w-full fixed md:static bottom-0 left-0 md:p-7 py-5 px-8 z-20">
              <div className="md:block hidden">
                <h2 className="font-bold text-xl mb-3 text-center">
                  まずは資料請求
                </h2>
                <p className="text-sm mb-5 text-center">
                  ご登録のメールアドレスに
                  <br />
                  資料のダウンロードリンクをお送りします。
                </p>
                <form className="flex flex-col gap-y-5 mb-4">
                  {inputs.map((input, index) =>
                    Array.isArray(input) ? (
                      <div key={index} className="flex gap-x-5">
                        {input.map((subInput, subIndex) => (
                          <div key={subIndex} className="w-1/2">
                            <label className="mb-1 block text-xs">
                              {subInput.label}
                              <span className="text-accent">*</span>
                            </label>
                            <input
                              name={subInput.label}
                              type={subInput.type}
                              className="bg-white rounded w-full p-3 text-sm"
                              placeholder={subInput.placeholder}
                              value={formValues[subInput.label] || ""}
                              onChange={e => handleChange(e, "fv")}
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      // 通常の入力フィールド
                      <div key={index}>
                        <label className="mb-1 block text-xs">
                          {input.label}
                          <span className="text-accent">*</span>
                        </label>
                        <input
                          name={input.label}
                          type={input.type}
                          className="bg-white rounded w-full p-3 text-sm"
                          placeholder={input.placeholder}
                          value={formValues[input.label] || ""}
                          onChange={e => handleChange(e, "fv")}
                        />
                      </div>
                    )
                  )}
                </form>

                <p className="text-xs mb-5">
                  フォームの送信前に
                  <Link to={`/privacy`} className="underline">
                    プライバシーポリシー
                  </Link>
                  をご確認ください。
                  <br />
                  <span className="text-accent">*</span>は必須項目です。
                </p>
              </div>
              <div className="text-center">
                <button className="bg-primary text-white font-bold rounded py-3 px-4 w-full">
                  お役立ち資料ダウンロード
                </button>
              </div>
            </div>
          </section>
          <section className="sns-problem bg-sns_palegrey">
            <div className="section-container">
              <h2 className="text-2xl font-bold text-center mb-10 md:mb-14">
                X<span className="text-xl">（旧Twitter）</span>の運用で
                <br />
                <span className="text-accent">
                  こんなお悩みはありませんか？
                </span>
              </h2>
              <div className="flex flex-col md:grid md:grid-cols-4 gap-5">
                <div>
                  <div className="mb-7 mx-auto text-center w-52">
                    <StaticImage
                      src="../../images/product/sns-account/problem_01.png"
                      alt="そもそもX運用の戦略が立てられない…"
                      layout="fullWidth"
                    />
                  </div>
                  <h3 className="text-xl font-bold mb-2">
                    そもそもX運用の戦略が立てられない…
                  </h3>
                  <p className="">
                    X運用についての知見が社内にないので、なんとなく運用してしまっている。当然KPIの設定もなんとなく…。
                  </p>
                </div>
                <div>
                  <div className="mb-7 mx-auto text-center w-52">
                    <StaticImage
                      src="../../images/product/sns-account/problem_02.png"
                      alt="日々の投稿やリプライ対応だけで精一杯…"
                      layout="fullWidth"
                    />
                  </div>
                  <h3 className="text-xl font-bold leading-relaxed mb-2">
                    日々の投稿やリプライ対応だけで精一杯…
                  </h3>
                  <p className="">
                    専任の担当者がいないので、通常業務と並行してどうにか運用している。本当はもっと色々試してみたいのに…。
                  </p>
                </div>
                <div>
                  <div className="mb-7 mx-auto text-center w-52">
                    <StaticImage
                      src="../../images/product/sns-account/problem_03.png"
                      alt="運用やキャンペーンの効果がわからない…"
                      layout="fullWidth"
                    />
                  </div>
                  <h3 className="text-xl font-bold leading-relaxed mb-2">
                    運用やキャンペーンの効果がわからない…
                  </h3>
                  <p className="">
                    キャンペーンも企画もやってみたけど、結局効果がわからない。分析も手探りなので次の打ち手に活かせない！
                  </p>
                </div>
                <div>
                  <div className="mb-7 mx-auto text-center w-52">
                    <StaticImage
                      src="../../images/product/sns-account/problem_04.png"
                      alt="Xの最新情報を追いかけられない…"
                      layout="fullWidth"
                    />
                  </div>
                  <h3 className="text-xl font-bold leading-relaxed mb-2">
                    Xの最新情報を追いかけられない…
                  </h3>
                  <p className="">
                    アルゴリズムに仕様の変更、とにかく流れが速くてついていけない！ずっとXの情報だけ追う時間なんてない。
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="sns-solution bg-white">
            <div className="section-container border-b border-lightgrey">
              <div className="justify-center flex items-baseline mb-2">
                <StaticImage
                  src="../../images/brand/blue.png"
                  alt="PARKLoT"
                  width={314}
                  layout="constrained"
                />
                <span className="font-bold text-2xl">の</span>
              </div>
              <h2 className="font-bold text-center text-2xl mb-5">
                X運用代行サービス
              </h2>
              <p className="text-center mb-4">
                PARKLoTなら、手のかかるXの運用や
                <br className="md:hidden" />
                キャンペーンを
                <br className="md:block hidden" />
                効果検証と改善まで全部お任せ
              </p>
              <div className="mx-auto max-w-2xl">
                <StaticImage
                  src="../../images/product/sns-account/flow.png"
                  alt="売上アップに効果的なX特化型ファンマーケティングを実施します"
                  layout="fullWidth"
                />
              </div>
            </div>
            <div className="section-container">
              <h2 className="text-center font-bold text-2xl mb-5">
                選ばれる理由
              </h2>
              <p className="text-center mb-12">
                アカウント設計や運用代行だけでなく、キャンペーンツールまで自社開発。
                <br />
                Xの仕様やアルゴリズムを熟知した施策を提案・実行できます！
              </p>
              <div className="flex flex-col md:flex-row gap-y-5 md:gap-x-5 mb-12 md:items-center">
                <div className="relative md:w-80 w-full">
                  <span className="font-mont text-primary font-bold text-6xl -top-2 absolute right-8 z-10">
                    01
                  </span>
                  <StaticImage
                    src="../../images/product/sns-account/reason_01.png"
                    alt="アカウント設計から運用・改善提案まで"
                    layout="fullWidth"
                  />
                </div>
                <div className="flex-1">
                  <h3 className="font-bold text-primary text-xl text-sns_deep_green mb-5">
                    アカウント設計から運用・改善提案まで
                  </h3>
                  <p>
                    SNSの運用支援というと、「戦略のみ」「運用代行のみ」「キャンペーンのみ」という形でのサポートが多く、全てに対応可能な企業は多くありません。広告代理店に依頼すれば手間はかかりませんが、その分費用が跳ね上がります。
                    <br />
                    当社はお客様の「社外SNS戦略部署」として、戦略から実行、改善までのサポートが可能です。
                  </p>
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-y-5 md:gap-x-5 mb-12 md:items-center">
                <div className="relative md:w-80 w-full">
                  <span className="font-mont text-accent font-bold text-6xl -top-2 absolute left-8 z-10">
                    02
                  </span>
                  <StaticImage
                    src="../../images/product/sns-account/reason_02.png"
                    alt="3年間で200社を支援して獲得したノウハウ"
                    layout="fullWidth"
                  />
                </div>
                <div className="flex-1">
                  <h3 className="font-bold text-accent text-xl mb-5">
                    3年間で200社を支援して獲得したノウハウ
                  </h3>
                  <p>
                    当社は3年以上前からSNSマーケティング支援ツールを開発・提供しています。そして過去200社以上のお客様にツールをご利用いただく中で、Xアカウントの運営・キャンペーンで成果を出すためのノウハウを獲得しました。
                    <br />
                    その経験を生かし、お客様の業種に合わせた最適な運用とキャンペーン施策をご提案いたします。
                  </p>
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-y-5 md:gap-x-5 mb-12 md:items-center">
                <div className="relative md:w-80 w-full">
                  <span className="font-mont text-primary font-bold text-6xl -top-2 absolute right-8 z-10">
                    03
                  </span>
                  <StaticImage
                    src="../../images/product/sns-account/reason_03.png"
                    alt="3年間で200社を支援して獲得したノウハウ"
                    layout="fullWidth"
                  />
                </div>
                <div className="flex-1">
                  <h3 className="font-bold text-xl text-sns_deep_green mb-5">
                    ツールを使用したキャンペーンで効果最大化
                  </h3>
                  <p>
                    当社が開発・提供しているSNSマーケティング支援ツール「PARKLoT」を使えば、プレゼントキャンペーンの企画や実施も成果を最大限まで高めることができます。
                    <br />
                    また通常一週間以上かかる企画から実行までを最短1日に短縮できるので、少コスト＆短期間でキャンペーンのPDCAを回すことができます。
                  </p>
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-y-5 md:gap-x-5 mb-12 md:items-center">
                <div className="relative md:w-80 w-full">
                  <span className="font-mont text-accent font-bold text-6xl -top-2 absolute left-8 z-10">
                    04
                  </span>
                  <StaticImage
                    src="../../images/product/sns-account/reason_04.png"
                    alt="Xのアルゴリズムとルールを熟知したサポート"
                    layout="fullWidth"
                  />
                </div>
                <div className="flex-1">
                  <h3 className="font-bold text-accent text-xl mb-5">
                    Xのアルゴリズムとルールを熟知したサポート
                  </h3>
                  <p>
                    TwitterからXへとサービス名が変わり、機能やルール、インプレッションを増やす方法も日々目まぐるしく変わる中、当社はX運用のプロフェッショナルとして、仕様変更や規約変更もいち早くキャッチアップし、お客様の施策に反映いたします。
                    <br />
                    自社で専任の担当者をつけることが難しいお客様でも、安心して運用できるように。
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="yellow-stripe">
            <div className="section-container__lg">
              <h2 className="text-center font-bold text-2xl mb-5">
                PARKLoTのX運用代行は
                <span className="text-accent">ここまでできる！</span>
              </h2>
              <p className="mb-10 text-center">
                バラエティに富んだキャンペーンで、商品・サービスの魅力を拡散。
                <br />
                ワクワクするキャンペーンを顧客と一緒に楽しむことで、ファンを増やします。
              </p>
              <ContentsSwiper />
              <div className="text-center mb-8">
                <FontAwesomeIcon
                  icon={faPlus}
                  className="text-sns_deep_green mb-2"
                  size="2xl"
                />
                <h4 className="text-2xl font-bold text-sns_deep_green">
                  さらに豊富な無料オプション
                </h4>
              </div>
              <OptionsSwiper />
            </div>
          </section>
          <section className="document-dl-section">
            <div className="section-container relative">
              <h3 className="white-shadow text-center text-accent font-bold text-xl mb-4">
                <span className="cv-speech-bubbles">
                  今日からすぐに使える
                  <br />
                  お役立ち資料
                </span>
              </h3>
              <div className="text-center mb-5">
                <span className="font-bold bg-accent text-white text-xl rounded py-1 px-3">
                  企業アカウント特化
                </span>
              </div>
              <h4 className="white-shadow text-4xl font-bold text-center mb-5">
                X運用改善
                <br />
                チェックリスト30選
              </h4>
              <div className="text-center mb-7">
                <button className="text-center rounded py-2 px-5 text-white bg-primary font-bold">
                  お役立ち資料ダウンロード
                </button>
              </div>
              <div className="checklist-img__dl-section mx-auto absolute hidden md:block w-80 top-1/2 -translate-y-1/2">
                <StaticImage
                  src="../../images/product/sns-account/checklist.png"
                  alt="チェックリスト"
                />
              </div>
              <div className="md:hidden mx-auto w-80">
                <StaticImage
                  src="../../images/product/sns-account/checklist_sm.png"
                  alt="チェックリスト"
                />
              </div>
            </div>
          </section>
          <section className="bg-white">
            <div className="section-container">
              <h2 className="text-2xl text-center font-bold mb-5">
                こんな企業様におすすめ
              </h2>
              <p className="mb-12 text-center">
                アカウントがまだない？SNSマーケターがいない？
                <br />
                そんな企業様こそ、お任せください！
              </p>
              <div className="flex flex-col md:grid md:grid-cols-3 md:gap-x-10 gap-y-10 md:gap-y-0">
                <div>
                  <div className="text-center">
                    <StaticImage
                      src="../../images/product/sns-account/point_01.png"
                      alt="スタートダッシュしたい！"
                      layout="constrained"
                      width={140}
                    />
                  </div>
                  <h3 className="font-bold text-center text-xl mb-2 mt-7">
                    スタートダッシュしたい！
                  </h3>
                  <p>
                    アカウントをこれから作りたい・アカウントはあるけど何もできていない企業様の、フォロワー獲得からファン化までを短期間で一気にサポートいたします！
                  </p>
                </div>
                <div>
                  <div className="text-center">
                    <StaticImage
                      src="../../images/product/sns-account/point_02.png"
                      alt="いずれ内製化したい！"
                      layout="constrained"
                      width={140}
                    />
                  </div>
                  <h3 className="font-bold text-center text-xl mb-2 mt-7">
                    いずれ内製化したい！
                  </h3>
                  <p>
                    今は手が足りないけれどもいずれは内製化していきたい、そんな企業様にも、当社がご提供するノウハウのシェアと分析レポートがお役に立ちます。
                  </p>
                </div>
                <div>
                  <div className="text-center">
                    <StaticImage
                      src="../../images/product/sns-account/point_02.png"
                      alt="コスパを重視したい！"
                      layout="constrained"
                      width={140}
                    />
                  </div>
                  <h3 className="font-bold text-center text-xl mb-2 mt-7">
                    コスパを重視したい！
                  </h3>
                  <p>
                    広告代理店に頼む予算を、できれば企画や施策などの実務に割きたい。ワンストップで対応できる当社ならコスパも◎！コミュニケーションコストも抑えられます。
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="bg-sns_light_pink">
            <div className="section-container">
              <h2 className="text-2xl text-center font-bold mb-5">導入実績</h2>
              <p className="text-center mb-12">
                短期間でのフォロワー獲得から
                キャンペーン施策によるエンゲージメント率向上まで
              </p>
              <div className="flex flex-col md:flex-row md:gap-x-5 gap-y-12 mb-12">
                <div className="relative card-container">
                  <span className="absolute -top-5 left-5 bg-primary text-center font-bold px-3 py-2 text-white rounded">
                    スポーツ系サプリメント販売アカウント
                  </span>
                  <div className="bg-white px-8 py-4 rounded-lg shadow-lg text-center">
                    <StaticImage
                      src="../../images/product/sns-account/result_01.png"
                      alt="22フォロワーが2ヶ月半で10000フォロワー達成！"
                    />
                  </div>
                </div>
                <div className="relative card-container">
                  <span className="absolute -top-5 left-5 bg-primary text-center font-bold px-3 py-2 text-white rounded">
                    バーチャルアーティストアカウント
                  </span>
                  <div className="bg-white px-8 py-4 rounded-lg shadow-lg text-center">
                    <StaticImage
                      src="../../images/product/sns-account/result_02.png"
                      alt="5000フォロワーが1週間で12000フォロワー達成！"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:gap-x-5 gap-y-12">
                <div className="relative card-container">
                  <span className="absolute -top-5 left-5 bg-primary text-center font-bold px-3 py-2 text-white rounded">
                    アクティビティ予約サイトアカウント
                  </span>
                  <div className="bg-white px-8 py-4 rounded-lg shadow-lg text-center">
                    <StaticImage
                      src="../../images/product/sns-account/result_03.png"
                      alt="8000フォロワーが10日で15000フォロワー達成！"
                    />
                  </div>
                </div>
                <div className="relative card-container">
                  <span className="absolute -top-5 left-5 bg-primary text-center font-bold px-3 py-2 text-white rounded">
                    旅行予約サービスアカウント
                  </span>
                  <div className="bg-white px-8 py-4 rounded-lg shadow-lg text-center">
                    <StaticImage
                      src="../../images/product/sns-account/result_04.png"
                      alt="24000フォロワーが1ヶ月半で30000フォロワー達成！"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="bg-sns_light_green">
            <div className="section-container">
              <h2 className="text-2xl text-center font-bold mb-5">
                料金プラン
              </h2>
              <p className="text-center mb-8">
                企画・運用支援だけでなくツール利用もできるので
                <br />
                代理店運用と比較しても圧倒的なコスパ
              </p>
              <PriceSwiper />
            </div>
          </section>
          <section className="faq">
            <div className="section-container">
              <h2 className="text-2xl text-center font-bold md:mb-14 mb-10">
                よくある質問
              </h2>
              <div className="max-w-3xl mx-auto flex flex-col gap-y-7">
                {questions.map((question, index) => {
                  const isAnswerOpen = openQuestions.includes(index)
                  return (
                    <div key={index}>
                      <div
                        className="flex w-full bg-white rounded-t"
                        onClick={() => handleIconClick(index)}
                        role="button"
                      >
                        <div className="bg-primary text-center rounded-tl py-1 px-3 font-bold text-white font-mont text-xl">
                          Q
                        </div>
                        <h3 className="flex-1 text-lg py-2 pl-5 pr-3 font-bold text-sns_deep_green">
                          {question.title}
                        </h3>
                        <div className="py-2 px-3">
                          {isAnswerOpen ? (
                            <FontAwesomeIcon
                              icon={faMinus}
                              className="text-sns_deep_green"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faPlus}
                              className="text-sns_deep_green"
                            />
                          )}
                        </div>
                      </div>
                      {isAnswerOpen && (
                        <div className="mt-1 flex items-center bg-white py-5 px-3">
                          <span className="text-center py-1 pr-3 font-bold font-mont text-xl text-sns_deep_green">
                            A
                          </span>
                          <p className="pl-5">{question.answer}</p>
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          </section>
          <section className="inquiry bg-white">
            <div className="section-container">
              <h2 className="text-2xl text-center font-bold mb-5">
                資料ダウンロード
              </h2>
              <p className="mb-10 text-center">まずはお気軽に資料請求を！</p>
              <h3 className="text-center text-accent font-bold text-xl mb-4">
                <span className="cv-speech-bubbles">
                  今なら無料の資料請求で
                  <br />
                  限定特典をプレゼント！
                </span>
              </h3>
              <div className="text-center mb-3">
                <span className="font-bold bg-accent text-white text-xl rounded py-1 px-3">
                  企業アカウント特化
                </span>
              </div>
              <h4 className="text-2xl font-bold text-center mb-4">
                X運用改善チェックリスト30選
              </h4>
              <div className="md:relative static">
                <div className="md:absolute static checklist-img mx-auto mb-3">
                  <StaticImage
                    src="../../images/product/sns-account/checklist.png"
                    alt="X運用改善チェックリスト30選"
                    layout="fullWidth"
                  />
                </div>
                <div className="relative max-w-2xl mx-auto yellow-stripe md:p-12 p-5 flex flex-col">
                  <div className="absolute mobile-img">
                    <StaticImage
                      src="../../images/product/sns-account/hand.png"
                      alt="X運用改善チェックリスト30選"
                      layout="fullWidth"
                    />
                  </div>
                  <h5 className="text-center font-bold text-xl mb-4">
                    資料請求フォーム
                  </h5>
                  <p className="text-center mb-5">
                    ご登録のメールアドレスに資料のダウンロードリンクをお送りします。
                  </p>
                  <form className="flex flex-col gap-y-5 mb-4">
                    {inputs.map((input, index) =>
                      Array.isArray(input) ? (
                        <div key={index} className="flex gap-x-5">
                          {input.map((subInput, subIndex) => (
                            <div key={subIndex} className="w-1/2">
                              <label className="mb-1 block text-xs">
                                {subInput.label}
                                <span className="text-accent">*</span>
                              </label>
                              <input
                                name={subInput.label}
                                type={subInput.type}
                                className="bg-white rounded w-full p-3 text-sm"
                                placeholder={subInput.placeholder}
                                value={secondFormValues[subInput.label] || ""}
                                onChange={e => handleChange(e, "second")}
                              />
                            </div>
                          ))}
                        </div>
                      ) : (
                        // 通常の入力フィールド
                        <div key={index}>
                          <label className="mb-1 block text-xs">
                            {input.label}
                            <span className="text-accent">*</span>
                          </label>
                          <input
                            name={input.label}
                            type={input.type}
                            className="bg-white rounded w-full p-3 text-sm"
                            placeholder={input.placeholder}
                            value={secondFormValues[input.label] || ""}
                            onChange={e => handleChange(e, "second")}
                          />
                        </div>
                      )
                    )}
                  </form>

                  <p className="text-xs mb-5">
                    フォームの送信前に
                    <Link to={`/privacy`} className="underline">
                      プライバシーポリシー
                    </Link>
                    をご確認ください。
                    <br />
                    <span className="text-accent">*</span>は必須項目です。
                  </p>
                  <div className="text-center">
                    <button className="bg-primary text-white font-bold rounded py-3 px-4 w-full">
                      お役立ち資料ダウンロード
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    </>
  )
}

export default ActingSnsAccount
